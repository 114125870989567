import { Icon } from '@corify/components/icon/icon';
import { OverlayArrow } from '@corify/components/overlay-arrow/overlay-arrow';
import { distanceMap, popupClassnames } from '@corify/components/popup/popup';
import { LanguageOption } from '@corify/context/legal/legal-context';
import { Language } from '@corify/types/language';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { Button, Dialog, DialogTrigger, Popover } from 'react-aria-components';

type LanguageSelectProps = {
  onChange?: (value: Language) => void;
  options: readonly LanguageOption[];
  value: string;
};

export const LanguageSelect: FC<LanguageSelectProps> = ({ options, onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DialogTrigger>
      <Button className="flex items-center gap-2.5 font-bold text-white" onPress={() => setIsOpen(!isOpen)}>
        <Icon name="language" fill="currentColor" />
        {value.toUpperCase()}
      </Button>
      <Popover
        placement="bottom end"
        offset={distanceMap['large']}
        isOpen={isOpen}
        onOpenChange={value => setIsOpen(value)}
      >
        <OverlayArrow type="contextMenu" />
        <Dialog className={popupClassnames}>
          <div className="flex flex-col items-center py-2">
            <ul className="w-48" role="menu">
              {options.map(option => {
                const isSelected = option.value === value;

                return (
                  // TODO: add keyboard navigation for better a11y
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <li
                    key={option.value}
                    className={clsx('body2 flex cursor-pointer items-center p-4 hover:bg-BG', {
                      'font-bold text-purple': isSelected,
                    })}
                    onClick={() => {
                      onChange?.(option.value);
                      setIsOpen(false);
                    }}
                    role="menuitem"
                  >
                    <div className="flex w-full items-center justify-between">
                      <span>{option.label}</span>
                      {isSelected && (
                        <span>
                          <Icon name="check" />
                        </span>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </Dialog>
      </Popover>
    </DialogTrigger>
  );
};
