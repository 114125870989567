import { ClientType } from '@corify/types/client';
import { GlobalUserRole, UserType } from '@corify/types/user';

export const mapUserRole = (
  globalUserRole: GlobalUserRole,
  clientType: ClientType | null,
  availableClientsCount: number,
  isClientSelected: boolean
): UserType => {
  if (globalUserRole === 'REGULAR' && availableClientsCount === 0) {
    return 'WITHOUT_CLIENT_AVAILABLE';
  }

  if (globalUserRole === 'REGULAR' && !isClientSelected) {
    return 'WITHOUT_CLIENT_SELECTED';
  }

  if (clientType) {
    return clientType;
  }

  if (globalUserRole === 'REGULAR') {
    throw new Error(`Client type ${clientType} is not handled`);
  }

  return globalUserRole;
};

export const getFullName = (user: { firstName: string; lastName: string } | string) =>
  typeof user === 'string' ? user : `${user.firstName} ${user.lastName}`;
