export const popupClassnames =
  'rounded-[4px] p-2 border border-lighterGrey bg-white shadow-[0px_0px_4px_0px] shadow-purpleLighter outline-none';

export type Distance = 'none' | 'small' | 'medium' | 'large';

export const distanceMap: Record<Distance, number> = {
  none: 0,
  small: 8,
  medium: 16,
  large: 24,
};
