import { useContext } from 'react';

import { ContextUser } from './user';
import { UserContext } from './user-context';
import { UserProvider } from './user-provider';

export const useUser: () => ContextUser = () => {
  const value = useContext(UserContext);

  if (value === null) {
    throw new Error(`${useUser.name} must be used within <${UserProvider.name} />`);
  }

  return value;
};
