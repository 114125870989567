import type { ClientType } from './client';

export type Title = 'MR' | 'MRS' | 'MX';
export type UserStatus = 'PENDING' | 'EXPIRED' | 'REVOKED' | 'ACTIVE' | 'DEACTIVATED';

export type ClientAssignment = {
  clientId: string;
  clientName: string;
  assignedAt: string;
  role: UserRole;
};

export type UserBase = {
  userId: string;
  title?: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type UserStatusDetails = {
  invitedOn: string;
  registeredOn?: string;
  status: UserStatus;
};

export type UserClientAssignments = {
  clientAssignments: ClientAssignment[];
};

export type User = UserBase & UserStatusDetails & UserClientAssignments;

export type PossibleAssignmentUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type PossibleAssignmentUsers = Record<UserRole, PossibleAssignmentUser[]>;

export type GlobalUserRole = 'CORIFY_ADMIN' | 'REGULAR' | 'MODEL_MANAGER' | 'MODEL_VALIDATOR' | 'CUSTOMER';

export type UserType =
  | Exclude<GlobalUserRole, 'REGULAR'>
  | ClientType
  | 'WITHOUT_CLIENT_AVAILABLE'
  | 'WITHOUT_CLIENT_SELECTED';

export type InvitedUserType = 'MAIN_CLIENT_ADMIN' | 'REGULAR';

export type BrokerRole = 'BROKER_MAIN_CLIENT_ADMIN' | 'BROKER_KEY_ACCOUNT_MANAGER' | 'BROKER_INSURANCE_EXPERT';
export type CustomerRole = 'CUSTOMER_RISK_MANAGER' | 'CUSTOMER_RISK_EXPERT' | 'CUSTOMER_MAIN_CLIENT_ADMIN';
export type InsurerRole = 'INSURER_MAIN_CLIENT_ADMIN' | 'INSURER_UNDERWRITER' | 'INSURER_INSURANCE_EXPERT';

export type FleetRole = 'FLEET_MANAGER' | 'CFM_ADMIN';

export type UserRole = BrokerRole | InsurerRole | CustomerRole | FleetRole;

export const brokerRiskContributionRoles: BrokerRole[] = ['BROKER_KEY_ACCOUNT_MANAGER', 'BROKER_INSURANCE_EXPERT'];
export const customerRiskContributionRoles: CustomerRole[] = ['CUSTOMER_RISK_MANAGER', 'CUSTOMER_RISK_EXPERT'];

export const riskContributionRoles: UserRole[] = [
  ...brokerRiskContributionRoles,
  ...customerRiskContributionRoles,
  'INSURER_UNDERWRITER',
  'INSURER_INSURANCE_EXPERT',
];

export const defaultClientRoles: UserRole[] = [
  'BROKER_MAIN_CLIENT_ADMIN',
  'INSURER_MAIN_CLIENT_ADMIN',
  'CUSTOMER_MAIN_CLIENT_ADMIN',
];

export interface Roles {
  globalUserRole: GlobalUserRole;
  clientType: ClientType | null;
  userRoles: {
    clientId: string;
    clientName: string;
    roles: UserRole[];
  }[];
  selectedClientId: string | null;
  brokerOfCustomer?: { brokerId: string; name: string };
  userId: string | null;
}

export type UserAssignmentData = {
  title: Title;
  firstName: string;
  lastName: string;
  id: string;
  status: UserStatus;
  email: string;
};

export type UserRoleAssignment = {
  role: UserRole;
  assignedAt: string;
};

export interface AssignedUser {
  roles: UserRole[];
  userId: string;
}

export type UserAssignment = {
  user: UserAssignmentData;
  roles: UserRoleAssignment[];
};

export type UserClient = {
  id: string;
  brokerOfCustomer?: {
    brokerId: string;
    name: string;
  };
  name: string;
  type: ClientType;
  userRoles: UserRole[];
};

export const isRegularUser = (userType: UserType) => ['INSURER', 'BROKER', 'CUSTOMER'].includes(userType);

export const isModeler = (userType: UserType) => ['MODEL_MANAGER', 'MODEL_VALIDATOR'].includes(userType);

export const isClientStateChecking = (userType: UserType) =>
  ['WITHOUT_CLIENT_AVAILABLE', 'WITHOUT_CLIENT_SELECTED'].includes(userType);

export type RiskUserAssignment = UserBase & {
  clientType?: UserType;
  status?: UserStatus;
};

// TODO: Replace with RiskUserAssignment when endpoints from https://corify.atlassian.net/browse/RDGA-3340 are unified to use 'userId' instead od 'id'
// TODO: Replace with RiskUserAssignment when endpoints from https://corify.atlassian.net/browse/RDGA-3340 are unified to use 'userId' instead od 'id'
// TODO: Replace with RiskUserAssignment when endpoints from https://corify.atlassian.net/browse/RDGA-3340 are unified to use 'userId' instead od 'id'
export type RiskUserAssignmentLegacy = Omit<UserBase, 'userId'> & {
  id: string;
  clientType?: UserType;
  status?: UserStatus;
};
